import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "./ReservationList.css";

interface Reservation {
  reservation_id: number;
  status: string;
  date_created: string;
  sub_slot: {
    sub_slot_id: number;
    start_time: string;
    end_time: string;
    status: string;
    date: string;
  };
  dog: {
    dog_id: number;
    name: string;
    breed: string;
  };
  service: {
    service_id: number;
    service_name: string;
  };
}

interface ReservationListProps {
  userId?: number;
  canEdit?: boolean; // Indique si l'utilisateur peut modifier le statut
}

const ReservationList: React.FC<ReservationListProps> = ({ userId, canEdit = false }) => {
  const [reservations, setReservations] = useState<Reservation[]>([]);

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        if (userId) {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-reservations-user/${userId}`);
          console.log("Réponse API Réservations:", response);  // Log pour vérifier la réponse
          if (response && response.data) {
            setReservations(response.data.reservations);
          }
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des réservations:', error);
      }
    };

    fetchReservations();
  }, [userId]);

  const handleStatusChange = async (reservationId: number, newStatus: string) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/update-reservation`, {
        reservation_id: reservationId,
        status: newStatus
      });
      setReservations(reservations.map(reservation => 
        reservation.reservation_id === reservationId ? { ...reservation, status: newStatus } : reservation
      ));
    } catch (error) {
      console.error('Erreur lors de la mise à jour du statut de la réservation:', error);
    }
  };

  return (
    <div className="reservation-list">
      {reservations.length === 0 ? (
        <p>Vous n'avez pas de réservation pour le moment</p>
      ) : (
        reservations.map((reservation) => (
          <div key={reservation.reservation_id} className="reservation-card">
            <h2>Réservation pour {reservation.dog.name}</h2>
            <p>Service: {reservation.service.service_name}</p>
            <p>Date: {reservation.sub_slot.date}</p>
            <p>Heure de début: {reservation.sub_slot.start_time}</p>
            <p>Heure de fin: {reservation.sub_slot.end_time}</p>
            <p>Chien: {reservation.dog.name} ({reservation.dog.breed})</p>
            <p>Statut: {reservation.status}</p>
            {canEdit && (
              <>
                <button onClick={() => handleStatusChange(reservation.reservation_id, 'Accepted')}>Accepter</button>
                <button onClick={() => handleStatusChange(reservation.reservation_id, 'Rejected')}>Rejeter</button>
              </>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default ReservationList;
