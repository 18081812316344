// TrainerDetail.tsx
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import AddDogModal from '../../modals/AddDogModal';
import CalendarComponent from '../../components/CalendarComponent/CalendarComponent';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SelectChangeEvent } from '@mui/material';
import sendEmail from '../../utils/emailForm';

interface Trainer {
  id: number;
  name: string;
  description: string;
  profile_photo_url: string;
  education_methods: string[];
  specialties: string[];
  photos: string[];
  email: string;
  ville: string;
  is_professional: boolean;
}

interface Dog {
  dogId: number;
  userId: number;
  name: string;
  breed: string;
  profilePhotoUrl: string;
  information: string;
  birthdate: string;
}

interface SubSlot {
  sub_slot_id: number;
  start_time: string;
  end_time: string;
  date: string;
  status: string;
  service_id: number;
}

interface TrainerService {
  service_id: number;
  service_name: string;
  price: number;
  duration: number;
  location: string;
  question: string;
  description: string;
}


const TrainerDetail: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [trainer, setTrainer] = useState<Trainer | null>(null);
  const [loading, setLoading] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [subSlots, setSubSlots] = useState<SubSlot[]>([]);
  const [servicesTrainer, setServicesTrainer] = useState<TrainerService[]>([]);
  const [dogs, setDogs] = useState<Dog[]>([]);
  const [selectedSubSlot, setSelectedSubSlot] = useState<number | null>(null);
  const [selectedSubSlotInfo, setSelectedSubSlotInfo] = useState<string | null>(null);
  const [selectedDog, setSelectedDog] = useState<number | null>(null);
  const [isAddDogModalOpen, setIsAddDogModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<TrainerService | null>(null);
  const [clientAnswer, setClientAnswer] = useState<string>('');


  const { isAuthenticated, user } = useAuth();

  useEffect(() => {
    if (id) {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/get-trainer/${id}`)
        .then((response) => {
          setTrainer(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération du formateur:', error);
          setLoading(false);
        });

      // Récupération des services spécifiques à l'éducateur (services_trainer)
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/get-services/${id}`)
        .then((response) => {
          setServicesTrainer(response.data.services);
        })
        .catch((error) => {
          console.error('Error fetching trainer services:', error);
        });
    }
  }, [id]);

  useEffect(() => {
    if (isAuthenticated && user && user.user_id) {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/get-dogs/${user.user_id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          setDogs(response.data.dogs);
        })
        .catch((error) => {
          console.error('Erreur lors de la récupération des chiens dans TrainerDetail:', error);
        });
    }
  }, [isAuthenticated, user]);

  const openAddDogModal = () => {
    setIsAddDogModalOpen(true);
  };

  const closeAddDogModal = () => {
    setIsAddDogModalOpen(false);
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const nextImage = () => {
    if (trainer && trainer.photos.length > 1) {
      setCurrentImageIndex((currentImageIndex + 1) % trainer.photos.length);
    }
  };

  const prevImage = () => {
    if (trainer && trainer.photos.length > 1) {
      setCurrentImageIndex((currentImageIndex - 1 + trainer.photos.length) % trainer.photos.length);
    }
  };

  const handleViewSlots = (serviceId: number) => {
    const service = servicesTrainer.find((s) => s.service_id === serviceId);
    setSelectedService(service || null);

    setSelectedSubSlot(null);
    setSelectedSubSlotInfo(null);

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/get-sub-slots`)
      .then((response) => {
        const filteredSlots = response.data.subSlots.filter(
          (slot: SubSlot) => slot.service_id === serviceId
        );
        setSubSlots(filteredSlots);
      })
      .catch((error) => {
        console.error('Error fetching sub-slots:', error);
      });
  };

  const handleSubSlotSelect = (subSlotId: number) => {
    if (!isAuthenticated) {
      // Afficher une notification toast si l'utilisateur n'est pas connecté
      toast.warning('Vous devez être connecté pour sélectionner un créneau.');
      return; // Empêcher la sélection si l'utilisateur n'est pas connecté
    }
    setSelectedSubSlot(subSlotId);
    const selectedSlot = subSlots.find((slot) => slot.sub_slot_id === subSlotId);
    if (selectedSlot && selectedService) {
      setSelectedSubSlotInfo(
        `Vous avez sélectionné le ${selectedSlot.date} de ${selectedSlot.start_time} à ${selectedSlot.end_time} pour le service ${selectedService.service_name}`
      );
    }
  };

  const handleDogSelect = (event: SelectChangeEvent) => {
    setSelectedDog(Number(event.target.value));
  };

  // TrainerDetail.tsx
  const handleReservation = () => {
    if (selectedSubSlot && selectedDog) {
      if (selectedService && selectedService.question && !clientAnswer.trim()) {
        toast.warning('Veuillez répondre à la question pour continuer.');
        return; // Empêcher la réservation si la réponse est manquante
      }

      const reservationData: any = {
        sub_slot_id: selectedSubSlot,
        dog_id: selectedDog,
      };

      if (selectedService && selectedService.question) {
        reservationData.client_answer = clientAnswer;
      }
      axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/make-reservation`, reservationData)
        .then(async (response) => {
          toast.success('Réservation effectuée avec succès !');
          setSubSlots((prevSubSlots) =>
            prevSubSlots.map((slot) =>
              slot.sub_slot_id === selectedSubSlot ? { ...slot, status: 'waiting' } : slot
            )
          );
          setSelectedSubSlot(null);
          setSelectedSubSlotInfo(null);
          setSelectedDog(null);
          setClientAnswer('');

          // Vérifier que 'user' et 'trainer' ne sont pas null
          if (user && trainer) {
            // Envoi d'e-mail à l'utilisateur
            try {
              await sendEmail({
                toEmail: user.email,
                subject: 'Votre demande de réservation a été prise en compte',
                htmlContent: `
                  <p>Bonjour ${user.firstName},</p>
                  <p>Votre demande de réservation a été prise en compte. Nous reviendrons vers vous dès que possible.</p>
                  <p>Cordialement,<br>L'équipe Rintintin Booking</p>
                `,
              });
              toast.success("Un e-mail de confirmation a été envoyé.");
            } catch (error) {
              console.error("Erreur lors de l'envoi de l'e-mail à l'utilisateur:", error);
              toast.error("Erreur lors de l'envoi de l'e-mail de confirmation à l'utilisateur.");
            }

            // Envoi d'e-mail au professionnel
            
          // Envoi d'e-mail au professionnel
            try {
              await sendEmail({
                toEmail: trainer.email,
                subject: 'Nouvelle demande de réservation',
                htmlContent: `
                  <p>Bonjour ${trainer.name},</p>
                  <p>Vous avez une nouvelle demande de réservation de la part de ${user.firstName} ${user.lastName}.</p>
                  <p>Cordialement,<br>L'équipe Rintintin Booking</p>
                `,
              });
              toast.success("Un e-mail a été envoyé au professionnel.");
          } catch (error) {
            console.error("Erreur lors de l'envoi de l'e-mail au professionnel:", error);
            toast.error("Erreur lors de l'envoi de l'e-mail au professionnel.");
          }
          } 
          else {
            console.error("Les informations de l'utilisateur ou du formateur sont manquantes.");
            toast.error("Erreur : Les informations de l'utilisateur ou du formateur sont manquantes.");
          }
        })
        .catch((error) => {
          console.error('Erreur lors de la réservation:', error);
          toast.error('Erreur lors de la réservation.');
        });
    } else {
      toast.warning('Veuillez sélectionner un créneau et un chien.');
    }
  };

  

  // Composant BackButton
  const BackButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
    <button className="carousel-button" onClick={onClick}>
      ⬅
    </button>
  );

  // Composant TrainerCarousel
  const TrainerCarousel: React.FC<{
    photos: string[];
    currentImageIndex: number;
    nextImage: () => void;
    prevImage: () => void;
  }> = ({ photos, currentImageIndex, nextImage, prevImage }) => (
    <div className="carousel">
      {photos.length > 1 && (
        <button className="carousel-nav-button left" onClick={prevImage}>
          ⬅
        </button>
      )}
      <img src={photos[currentImageIndex]} alt="Trainer" className="carousel-image" />
      {photos.length > 1 && (
        <button className="carousel-nav-button right" onClick={nextImage}>
          ➡
        </button>
      )}
    </div>
  );

  // Composant TrainerInfo
  const TrainerInfo: React.FC<{ trainer: Trainer }> = ({ trainer }) => (
    <div className="trainer-info"   style={{
      background: '#fff',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.3)',
      marginBottom: '20px',
      margin: '20px',
    }}>
      <h1>{trainer.name}</h1>
      <p className="trainer-ville">📍 {trainer.ville}</p>
      <p>{trainer.description}</p>
      {trainer.education_methods.length > 0 && (
        <TrainerEducationMethods methods={trainer.education_methods} />
      )}
      {trainer.specialties.length > 0 && <TrainerSpecialties specialties={trainer.specialties} />}
    </div>
  );

  // Composant TrainerEducationMethods
  const TrainerEducationMethods: React.FC<{ methods: string[] }> = ({ methods }) => (
    <div className="trainer-education-methods">
      <h2>🧑‍🏫 Méthodes d'éducation</h2>
      <ul>
        {methods.map((method, index) => (
          <li key={index}>{method}</li>
        ))}
      </ul>
    </div>
  );

  // Composant TrainerSpecialties
  const TrainerSpecialties: React.FC<{ specialties: string[] }> = ({ specialties }) => (
    <div className="trainer-specialties">
      <h2>💼 Spécialités</h2>
      <ul>
        {specialties.map((specialty, index) => (
          <li key={index}>{specialty}</li>
        ))}
      </ul>
    </div>
  );

  if (loading) {
    return <div>Chargement...</div>;
  }

  if (!trainer) {
    return <div>Éducateur non trouvé</div>;
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        marginTop: '4rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'flex-start',
        }}
      >
        <div style={{ width: '75%' }}>
          <BackButton onClick={handleBackButtonClick} />
          <TrainerCarousel
            photos={trainer.photos}
            currentImageIndex={currentImageIndex}
            nextImage={nextImage}
            prevImage={prevImage}
          />
          <TrainerInfo trainer={trainer} />

          {trainer.is_professional && (
            <>
              {servicesTrainer.map((service) => (
                <div
                  key={service.service_id}
                  style={{
                    backgroundColor: '#fff',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    marginBottom: '20px',
                  }}
                >
                  <Typography variant="h5">
                    {service.service_name} ({service.price}€ - {service.duration} min) à{' '}
                    {service.location}
                  </Typography>
                  {service.description && (
                  <Typography variant="body1" sx={{ marginTop: '10px' }}>
                    {service.description}
                  </Typography>
                )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleViewSlots(service.service_id)}
                    sx={{
                      backgroundColor: '#333',
                      '&:hover': {
                        backgroundColor: '#555',
                      },
                      marginTop: '10px',
                    }}
                  >
                    Voir les créneaux
                  </Button>
                </div>
              ))}

              {selectedService && subSlots.length > 0 && (
                <div>
                  <Typography variant="h6">
                    Créneaux disponibles pour {selectedService.service_name} :
                  </Typography>
                  <CalendarComponent subSlots={subSlots} onSubSlotSelect={handleSubSlotSelect} />
                </div>
              )}
            </>
          )}
        </div>

        {/* Carte de réservation */}
        <Card
          variant="outlined"
          sx={{
            border: '2px solid #ffcc00',
            backgroundColor: '#f0f4f8',
            borderRadius: '15px',
            padding: '20px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '20%',
          }}
        >
          <CardContent>
            <Typography
              variant="h5"
              component="div"
              sx={{ color: '#333', fontSize: '1.6rem', marginBottom: '15px' }}
            >
              Réserver un service
            </Typography>

            {!isAuthenticated ? (
              <div>
                <Typography variant="body1">
                  Vous devez être connecté pour réserver un service.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate('/login')}
                  sx={{ marginTop: '20px', backgroundColor: '#ffcc00', color: '#333' }}
                >
                  Se connecter
                </Button>
              </div>
            ) : (
              <>
                {selectedSubSlotInfo ? (
                  <Typography
                    variant="body1"
                    sx={{ fontStyle: 'italic', marginTop: '10px', color: '#00796b' }}
                  >
                    {selectedSubSlotInfo}
                  </Typography>
                ) : (
                  <Typography variant="body1">
                    Veuillez sélectionner un créneau pour continuer.
                  </Typography>
                )}

                {/* Bouton pour ajouter un chien et liste des chiens */}
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={openAddDogModal}
                    sx={{ marginTop: '20px', backgroundColor: '#333' }}
                  >
                    Ajouter un chien
                  </Button>

                  {dogs.length > 0 ? (
                    <FormControl fullWidth margin="normal">
                      <InputLabel id="dog-select-label">Choisir un chien</InputLabel>
                      <Select
                        labelId="dog-select-label"
                        value={
                          selectedDog !== undefined && selectedDog !== null
                            ? selectedDog.toString()
                            : ''
                        }
                        onChange={handleDogSelect}
                        label="Choisir un chien"
                      >
                        {dogs.map((dog) => (
                          <MenuItem key={dog.dogId} value={dog.dogId.toString()}>
                            {dog.name} - {dog.breed}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : (
                    <Typography variant="body1" sx={{ marginTop: '10px' }}>
                      Vous n'avez pas encore ajouté de chien à votre compte.
                    </Typography>
                  )}
                </div>

                {selectedService && selectedService.question && (
                <div>
                  <Typography variant="body1" sx={{ marginTop: '10px' }}>
                    {selectedService.question}
                  </Typography>
                  <TextField
                    label="Votre réponse"
                    multiline
                    rows={4}
                    variant="outlined"
                    fullWidth
                    value={clientAnswer}
                    onChange={(e) => setClientAnswer(e.target.value)}
                    sx={{ marginTop: '10px' }}
                  />
                </div>
              )}

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleReservation}
                  disabled={!selectedSubSlot || !selectedDog}
                  sx={{
                    marginTop: '20px',
                    backgroundColor: '#ffcc00',
                    color: '#333',
                    '&:hover': {
                      backgroundColor: '#e0b127',
                    },
                  }}
                >
                  Réserver
                </Button>
              </>
            )}
          </CardContent>
        </Card>
      </div>

      <AddDogModal
        isOpen={isAddDogModalOpen}
        onRequestClose={closeAddDogModal}
        onDogAdded={(newDog) => {
          setDogs([...dogs, newDog]);
          closeAddDogModal();
        }}
      />

      <ToastContainer />
    </div>
  );
};

export default TrainerDetail;
