import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Configuration de la locale française et du début de semaine à lundi
moment.updateLocale('fr', {
  week: {
    dow: 1, // 1 = Lundi
  },
});

interface SubSlot {
  sub_slot_id: number;
  start_time: string;
  end_time: string;
  date: string;
  status: string;
}

interface CalendarComponentProps {
  subSlots: SubSlot[];
  onSubSlotSelect: (subSlotId: number) => void;
}

const localizer = momentLocalizer(moment);

interface MyEvent {
  id: number;
  title: string;
  start: Date;
  end: Date;
  status: string;
}

const CalendarComponent: React.FC<CalendarComponentProps> = ({ subSlots, onSubSlotSelect }) => {
  const [currentDate, setCurrentDate] = React.useState(new Date());

  const events: MyEvent[] = subSlots.map((slot) => ({
    id: slot.sub_slot_id,
    title: `Créneau ${slot.start_time} - ${slot.end_time}`,
    start: new Date(`${slot.date}T${slot.start_time}`),
    end: new Date(`${slot.date}T${slot.end_time}`),
    status: slot.status,
  }));

  const eventPropGetter = (event: MyEvent) => {
    let backgroundColor = '#3174ad'; // Couleur par défaut

    if (event.status === 'waiting') {
      backgroundColor = '#ffcc00';
    } else if (event.status === 'validated') {
      backgroundColor = '#28a745';
    } else if (event.status === 'undefined') {
      backgroundColor = '#007bff';
    }

    return {
      style: {
        backgroundColor,
        color: 'white',
      },
    };
  };

  // Déterminer le premier créneau valide pour définir la date par défaut
  const firstValidSlot = events.find(event => event.status === 'validated');
  const defaultDate = firstValidSlot ? firstValidSlot.start : new Date();

  // Calculer les heures minimum et maximum dynamiquement
  const minTime = events.length > 0 ? new Date(Math.min(...events.map(event => event.start.getTime()))) : new Date();
  const maxTime = events.length > 0 ? new Date(Math.max(...events.map(event => event.end.getTime()))) : new Date();

  // Fonction pour changer de semaine
  const navigateWeek = (direction: 'PREV' | 'NEXT') => {
    const newDate = moment(currentDate).add(direction === 'NEXT' ? 1 : -1, 'week').toDate();
    setCurrentDate(newDate);
  };

  return (
    <div style={{ height: '500px' }}>
      <div className="custom-toolbar">
        <button onClick={() => navigateWeek('PREV')}>⬅</button>
        <button onClick={() => navigateWeek('NEXT')}>➡</button>
      </div>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        date={currentDate}
        onNavigate={date => setCurrentDate(date)}
        onSelectEvent={(event) => {
          const myEvent = event as MyEvent;
          if (myEvent.status === 'undefined') {
            onSubSlotSelect(myEvent.id as number);
          } else {
            toast.info('Ce créneau n\'est pas disponible.');
          }
        }}
        eventPropGetter={eventPropGetter}
        step={30} // Intervalle de temps en minutes
        timeslots={2} // Nombre de créneaux par heure
        defaultView="week" // Vue par défaut
        views={['week']} // Seule la vue "semaine" est disponible
        min={minTime}
        max={maxTime}
        defaultDate={defaultDate} // Démarrer à la date du premier créneau valide
        toolbar={false} // Désactiver la barre d'outils par défaut
      />
    </div>
  );
};

export default CalendarComponent;
